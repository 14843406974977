<template>
  <v-dialog
    v-model="OpenDialogShowCalls"
    persistent
    max-width="700"
    :scrollable="true"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col cols="2">
                <v-img :src="notesImg" max-width="30" alt=""> </v-img>
              </v-col>
              <v-col cols="10">
                <span>
                  Notas
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            style="text-align: end; cursor: pointer;"
            @click="closeModal"
          >
            <v-icon left color="#858C94">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="height: 400px;">
        <v-container>
          <v-row>
            <v-col cols="10">
              <v-text-field
                class="share-bar-clase"
                label="Buscar"
                outlined
                dense
                prepend-inner-icon="mdi-magnify"
                :light="true"
                v-model="filtro"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-expansion-panels
            class="pb-5"
            v-for="(nota, idx) in showNotes"
            :key="idx"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ nota.disposicion || nota.disposicion }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <h4>
                  Tipo de llamada:
                </h4>
                <div>
                  {{ nota.tipo_llamada || nota.tipo_llamada }}
                </div>
                <v-row class="pt-4">
                  <v-col cols="12">
                    <h4>
                      Creado por: {{ getUsuario(nota.usuario) }} el: {{ nota.dateFormat || $timezone.formatServerTimezone(nota.fecha) }}
                    </h4>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card-text>
      <v-card-actions style="justify-content: center;">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <v-pagination
            v-model="page"
            :length="pages"
            circle
            color="#0070C0"
          ></v-pagination>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import imagenNotes from "@/assets/notes.svg";
import { mainAxios } from '@/mainAxios.js';

export default {
  components: {},
  props: {
    OpenDialogShowCalls: {
      type: Boolean,
      default: false,
    },
    llamadas: Array,
  },

  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      data: {
        nombre: null,
        enviarPorCorreo: false,
        nota: null,
        tipo: "Nota",
        tipo_documento: null,
      },
      run: false,
      formData: null,
      page: 1,
      limit: 5,
      filtro: null,
      notesImg: imagenNotes,
      agentes:[]
    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`,
            id: element.id,
            uuid: element.uuid,
          });
        });

        this.agentes.map((element) => {
          this.agenteId == element.id ? (this.agente = element.nombre) : "";
        });
      });
    },
    getUsuario(id){
      return this.agentes.find((e) => e.id == id).nombre
    }
  },
  computed: {
    pages() {
      const totalPages = Math.ceil(this.notasFiltradas.length / this.limit);
      return totalPages < 1 ? 1 : totalPages;
    },
    notasFiltradas() {
      if (!this.filtro) {
        return this.llamadas;
      }
      const filtroMin = this.filtro.toLowerCase().trim();
      return this.llamadas.filter((nota) => {
        return nota.disposicion.toLowerCase().includes(filtroMin);
      });
    },
    indiceInicio() {
      return (this.page - 1) * this.limit;
    },
    indiceFinal() {
      return this.page * this.limit - 1;
    },
    showNotes() {
      return this.notasFiltradas.slice(this.indiceInicio, this.indiceFinal + 1);
    },
  },
  mounted(){
    this.obtenerAgentes()
  }
};
</script>

<style scoped>
.custom-list {
  background-color: #0058fc;
  border-radius: 10px;
  text-align: center;
  margin: 5px 0px 5px 0px;
}

.v-expansion-panel {
  background-color: rgb(165, 55, 238);
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 5px !important;
  background-color: aliceblue;
}
.v-expansion-panel-header {
  background-color: aliceblue;
  border-radius: 5px;
}
</style>
