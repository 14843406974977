<template>
    <v-carousel ref="carousel" hide-delimiters :height="!openAll ? '150' : '300'"	>
      <template v-slot:prev="{ on, attrs }">
        <v-btn large v-bind="attrs" v-on="on" icon text>
          <v-icon color="#00a7e4" large>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn large v-bind="attrs" v-on="on" icon text>
          <v-icon color="#00a7e4" large>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <v-carousel-item>
        <v-row>
          <v-col md="4" lg="4" xl="4" style="padding-left: 30px;">
            <CommonCardCampaings
              :campaings="infoCards.campaings"
              :routesConfigDocsCard="routesConfigNoteCard"
              :openAll="openAll"
              :canEdit="canEdit"
              @getData="getData"  
              @showAll="showAll"
            />
          </v-col>
          <v-col md="4" lg="4" xl="4">
            <common-card-calls
              :llamadas="infoCards.llamadas"
              :routesConfigNoteCard="routesConfigNoteCard"
              :openAll="openAll"
              :canEdit="canEdit"
              @getData="getData"
              @showAll="showAll"
            />
          </v-col>
          <v-col md="4" lg="4" xl="4" style="padding-right: 30px;">
            <common-card-docs
              :docs="infoCards.docs"
              :routesConfigDocsCard="routesConfigNoteCard"
              :openAll="openAll"
              :canEdit="canEdit"
              @getData="getData"
              @showAll="showAll"
            />
          </v-col>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row>
          <v-col md="4" lg="4" xl="4" style="padding-left: 30px;">
            <common-card-notes
              :notas="infoCards.notas"
              :routesConfigNoteCard="routesConfigNoteCard"
              :openAll="openAll"
              :canEdit="canEdit"
              @getData="getData"
              @showAll="showAll"
            />
          </v-col>
          <v-col md="4" lg="4" xl="4">
            <common-card-email
              :emails="infoCards.emails"
              :emailRoutesConfig="emailRoutesConfig"
              :openAll="openAll"
              :canEdit="canEdit"
              @getData="getData"
              @showAll="showAll"
            />
          </v-col>
        </v-row>
      </v-carousel-item>

    </v-carousel>
  </template>
  
  <script>
  import CommonCardDocs from "@/components/commonComponents/cards/CommonCardDocs.vue";
  import CommonCardEmail from "@/components/commonComponents/cards/CommonCardEmail.vue";
  import CommonCardNotes from "@/components/commonComponents/cards/CommonCardNotes.vue";
  import CommonCardCampaings from "@/components/commonComponents/cards/CommonCardCampaings.vue";
  import CommonCardCalls from "@/components/commonComponents/cards/CommonCardCalls.vue";
  
  export default {
    components: {
      CommonCardDocs,
      CommonCardEmail,
      CommonCardNotes,
      CommonCardCampaings,
      CommonCardCalls
    },
    props: {
      infoCards: Object,
      routesConfigNoteCard: Object,
      personalData: Object,
      emailRoutesConfig: Object,
      transforToAgent: {
        type: Boolean,
        default: false,
      },
      canEdit: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        switch_data: 1,
        openAll: false,
      };
    },
  
    methods: {
      changeComponent(component) {
        this.switch_data = component;
      },
      getData() {
        this.$emit("getData");
      },
      showAll(val) {
        this.openAll = val;
      },
    },
  };
  </script>
  