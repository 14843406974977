<template>
  <div>
    <v-dialog
      v-model="OpenDialogShowCampaings"
      persistent
      max-width="700"
      :scrollable="true"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10">
              <v-row>
                <v-col cols="2">
                  <v-img :src="bullseyeImg" max-width="25" he alt=""> </v-img>
                </v-col>
                <v-col cols="10">
                  <span>
                    Campañas
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="2"
              style="text-align: end; cursor: pointer;"
              @click="closeModal"
            >
              <v-icon left color="#858C94">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="height: 400px;">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="share-bar-clase"
                  label="Buscar"
                  outlined
                  dense
                  prepend-inner-icon="mdi-magnify"
                  :light="true"
                  v-model="filtro"
                ></v-text-field>
              </v-col>
            </v-row>
            <div
              class="custom-div-campaings"
              v-for="(campaing, idx) in showNotes"
              :key="idx"
            >
              <v-row>
                <v-col cols="1">
                  <v-icon left color="#0070C0">
                    mdi-rotate-right
                  </v-icon>
                </v-col>
                <v-col cols="5">
                  {{ campaing.medio }}
                </v-col>
                <v-col cols="5">
                  {{ getFormatDate(campaing.fecha_lead) }}
                </v-col>
                <v-col cols="1" style="text-align: end;">
                  <v-btn icon @click="openModal(campaing.uuid)">
                    <v-icon color="#C1C7CD">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions style="justify-content: center;">
          <div
            style="display: flex; justify-content: center; align-items: center;"
          >
            <v-pagination
              v-model="page"
              :length="pages"
              :limit="5"
              circle
              color="#0070C0"
            ></v-pagination>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ShowCampaingDetailDialog
      v-if="showCampaingModal"
      :show="showCampaingModal"
      :origenUuid="origenUuid"
      @close="showCampaingModal = false"
    />
  </div>
</template>

<script>
import bullseye from "@/assets/bullseye_arrow_icon.svg";
import moment from "moment";

export default {
  components: {
    ShowCampaingDetailDialog: () => import("./ShowCampaingDetailDialog.vue"),
  },
  props: {
    OpenDialogShowCampaings: {
      type: Boolean,
      default: false,
    },
    campaings: Array,
  },

  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      run: false,
      formData: null,
      page: 1,
      limit: 5,
      filtro: null,
      bullseyeImg: bullseye,
      showCampaingModal: false,
      origenUuid: "",
    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    openCreateModal() {
      this.$emit("openCreateModal");
    },
    getFormatDate(date) {
      return moment(date)
        .subtract(6, "hours")
        .format("DD/MM/YYYY hh:mm:ss");
    },
    openModal(uuid) {
      this.origenUuid = uuid;
      this.showCampaingModal = true;
    },
  },
  computed: {
    pages() {
      const totalPages = Math.ceil(this.campaingsFiltradas.length / this.limit);
      return totalPages < 1 ? 1 : totalPages;
    },
    campaingsFiltradas() {
      if (!this.filtro) {
        return this.campaings;
      }
      const filtroMin = this.filtro.toLowerCase().trim();
      return this.campaings.filter((documento) => {
        return documento.medio.toLowerCase().includes(filtroMin);
      });
    },
    indiceInicio() {
      return (this.page - 1) * this.limit;
    },
    indiceFinal() {
      return this.page * this.limit - 1;
    },
    showNotes() {
      return this.campaingsFiltradas.slice(
        this.indiceInicio,
        this.indiceFinal + 1
      );
    },
  },
};
</script>

<style scoped>
.custom-list {
  background-color: #0058fc;
  border-radius: 10px;
  text-align: center;
  margin: 5px 0px 5px 0px;
}

.v-expansion-panel {
  background-color: rgb(165, 55, 238);
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 5px !important;
  background-color: aliceblue;
}
.v-expansion-panel-header {
  background-color: aliceblue;
  border-radius: 5px;
}

.custom-div-campaings {
  padding: 15px;
  border: 2px solid #00a7e4;
  border-radius: 23px;
  margin-block: 10px;
}
</style>
