<template>
    <div>
        <v-card
            class="mx-auto"
            color="white"
            width="100%"
            style="border-radius: 20px; box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;"
            elevation="0"
            >
            <v-card-title @click="showAll" v-if="!openAll" style="justify-content: center; cursor: pointer;">
                <v-icon 
                    width="50"
                    color="#0070c0"
                    alt="">
                    mdi-phone-outgoing
                </v-icon>
                <br>
            </v-card-title>
            <v-card-title  v-if="openAll" style="cursor: pointer;">
                <v-row>
                    <v-col cols="10" @click="showAll">
                        <v-row>
                            <v-col cols="2">
                                <v-icon 
                                    width="50"
                                    color="#0070c0"
                                    alt="">
                                    mdi-phone-outgoing
                                </v-icon>
                            </v-col>
                            <v-col cols="10">
                                <span>
                                    Llamadas
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

            </v-card-title>

            <v-card-text style="text-align: center;" v-if="!openAll  ">
                <span style="
                    font-size: 1.25rem;
                    font-weight: 500;
                    letter-spacing: 0.0125em;
                    line-height: 2rem;
                    word-break: break-all;
                    color: rgba(0, 0, 0, 0.87);
                "> 
                Llamadas
                </span>
            </v-card-text>
            <v-card-text v-if="openAll">
                <div v-if="llamadas.length > 0" style="height: 180px !important;">
                    <!-- <span class="gray--text gray--lighten-2">
                    Lista
                    </span> -->
                    <v-row v-for="(llamada, idx) in llamadas.slice(0, 3)" :key="idx">
                        <v-col cols="10" style="align-self: center;">
                            <h4 >
                                {{ llamada.disposicion }} - {{ getFormatDate(llamada.fecha) }} / {{ getAgenteName(llamada.usuario) }} / {{ llamada.tipo_llamada }}
                            </h4>
                        </v-col>
                        <v-col cols="2" style="text-align: end;">
                            <v-icon
                                left
                                color="#00a7e4"
                                size="26"
                                style="cursor: pointer;"
                                @click="OpenDialogShowCalls = true"
                            >
                                mdi-eye-outline
                            </v-icon>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="llamadas.length > 0">
                    <v-row style="text-align: center;">
                        <v-col cols="12">
                        <v-btn
                            elevation="0"
                            color="white"
                            @click="OpenDialogShowCalls = true"
                        >
                        <span style="color:#0070C0">
                            ver mas
                        </span>
                        </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div v-else style="height: 216px !important; text-align: center;" >
                    <v-row style="text-align: center;">
                        <v-col cols="12">
                            <h3>    
                                No hay nada por el momento
                            </h3>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
        <ShowCallsDialog
            :llamadas="llamadas"
            :OpenDialogShowCalls="OpenDialogShowCalls"
            @closeModal="OpenDialogShowCalls = false"
            @openCreateModal="OpenDialogCreateDocument = true"
        >
        </ShowCallsDialog>
    </div>
</template>

<script>
import ShowCallsDialog from '../dialogs/ShowCallsDialog.vue';
import addIcon from "@/assets/addIcon.svg";
import moment from "moment";
import { getUsersList } from "@/services/user/user.service";

export default {
  components: {
    ShowCallsDialog
  },

  data() {
    return {
        showStatus: false,
        docsArray:[],
        OpenDialogCreateDocument: false,
        OpenDialogShowCalls:false,
        AddIcon: addIcon,
        usuarios:[]
    };
  },
  props:{
    llamadas: Array,
    routesConfigDocsCard: Object,
    openAll: {
        type: Boolean,
        default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    }

  },
  watch:{
    openAll(val){
        this.showStatus = val
    }
  },

  methods: {
    getData(){
        this.OpenDialogCreateDocument = false;
        this.$emit("getData")
    },
    showAll(){
        this.$emit('showAll', !this.showStatus)
    },
    getFormatDate(date) {
      return moment(date)
        .subtract(6, "hours")
        .format("DD/MM/YYYY hh:mm:ss");
    },
    getAgenteName(id){
        console.log({id})
        var usuario = this.usuarios.find((e) => e.id == id)
        return usuario ? usuario.nombreCompleto : ' '
    }
  },
  async mounted(){
    this.usuarios = await getUsersList()
  }
};
</script>

<style>
  .rounded-card{
    border-radius: 20px;
    max-width:"100%";
    background-color: white;
}

.mi-clase-con-sombra {
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;
    border-radius: 20px;
}
