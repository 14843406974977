import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const getUsersList = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.post(
    "/v1/agente/list-filtro",
    {
      filtroBusqueda:'',
    },
    config
  );
  return serverResponse.data

};

